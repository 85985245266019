<template>
  <!-- 列表视图 -->
  <div class="list-view">
    <!-- 新增任务 -->
    <!-- <div class="mini-task-editor" style="color:#0076ff" v-if="dataList && dataList.type !== '-1'">
            <div v-if="miniEditor === false">
                <span class="active" style="cursor:pointer;" @click="handleActiveClick">
                    <i class="iconfont iconxinzeng2"></i>
                    <span>新增任务</span>
                </span>
            </div>
            <div v-else class="mimi-editor-main">
                <div class="editor-mian" id="editor-mian">

                    <el-input ref="input_task" @blur="inputTaskBlur" @focus="inputTaskFocus" v-model="taskData.name" placeholder="新增任务，回车快速创建" @keypress.native.enter="addTask"/>
                     <div style="padding: 5px;" :style="{visibility:  detailsShow ? 'visible':  'hidden'}">
                    <div style="padding: 5px;" v-show="detailsShow"> -->
    <!-- 点击打开大编辑窗  -->
    <!-- <i class="el-icon-alarm-clock active"> </i>
                        <span class="active" style="cursor:pointer;" @click="handleFullScenc">明天24:00截止</span>
                        &nbsp;&nbsp;&nbsp;
                        <i class="el-icon-s-custom active"> </i>
                        <span class="active" style="cursor:pointer;" @click="handleFullScenc">{{ currenrUser }}</span>
                    </div>
                </div>
                <div>
                    <div style="padding: 5px 15px">
                        <i class="iconfont iconquanping1 active" style="font-size: 30px; cursor:pointer;" @click="handleFullScenc"></i>
                    </div>
                </div>
                <div>
                    <div style="padding: 5px 15px">
                        <el-button class="add-btn" :loading="loading" @click="handleAdd">新建</el-button>
                    </div>
                </div>
            </div>
        </div> -->
    <!-- 列表主体 -->
    <div
      v-if="dataList && dataList.type !== '-1'"
      class="list-view-main"
      :style="miniEditor ? `height: calc(100% - 85px);` : 'height: calc(100%);'"
    >
      <ul
        v-if="dataList.data && dataList.data.length"
        style="height: 100%; overflow: auto"
        class="scrollCont"
        :loading="false"
        v-infinite-scroll="load"
      >
        <li
          class="list-view-item"
          v-for="(item, index) in getLocalizedData(dataList.data, [
            'title',
            'object_name',
          ])"
          :key="index"
        >
          <div
            class="left-item-status"
            :style="getStausColor(item.status)"
          ></div>
          <div class="list-item-main" @click="handleItemClick(item)">
            <div class="item-number">
              {{ $t("todoCom.TodoList.785059-NO",[item.flow_approval_number??'-'])}}
            </div>
            <div class="item-main-top">
              <!-- 优先级 -->
              <span
                v-if="item.priority && item.priority !== 0"
                class="task-priority"
                >{{ "!".repeat(item.priority) }} &nbsp;</span
              >
              <!-- 标题 -->
              <span class="header-title">
                {{ item.t_object_name }}
              </span>
              &nbsp;
              <!-- 状态 -->
              <!--  v-if="item.msg_type !== 3" -->
              <span
                class="header-status"
                :style="getStatusLabeColor(item.status)"
              >
                {{ getStutustext(item.status) }}
              </span>
              <!-- <span
                v-else
                class="header-status"
                :style="getStatusLabeColor(item.status)"
              >
                {{ $t("ListView.index.595688-0") }}
              </span> -->
            </div>

            <div class="item-main-bottom">
              <div class="info-name">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <g clip-path="url(#clip0_4527_6766)">
                    <path d="M10.7234 8.63876C10.4774 8.55165 10.2125 8.50387 9.93682 8.50387C8.63365 8.50387 7.57702 9.56664 7.57702 10.8772C7.57702 11.4237 7.76045 11.9267 8.0688 12.3278H1.01548C0.947726 12.3278 0.882746 12.3009 0.834836 12.2529C0.786925 12.205 0.76001 12.1401 0.76001 12.0723V0.320596C0.76001 0.252841 0.786925 0.187861 0.834836 0.13995C0.882746 0.0920402 0.947726 0.0651245 1.01548 0.0651245L10.4679 0.0651245C10.5357 0.0651245 10.6007 0.0920402 10.6486 0.13995C10.6965 0.187861 10.7234 0.252841 10.7234 0.320596V8.63876Z" fill="#12778D"/>
                    <path d="M11.8914 9.60592C11.6454 9.51881 11.3804 9.47103 11.1048 9.47103C9.80162 9.47103 8.74499 10.5338 8.74499 11.8444C8.74499 12.3908 8.92842 12.8938 9.23677 13.2949H2.18345C2.1157 13.2949 2.05071 13.268 2.0028 13.2201C1.95489 13.1722 1.92798 13.1072 1.92798 13.0395V1.28776C1.92798 1.22 1.95489 1.15502 2.0028 1.10711C2.05071 1.0592 2.1157 1.03229 2.18345 1.03229H11.6359C11.7037 1.03229 11.7686 1.0592 11.8166 1.10711C11.8645 1.15502 11.8914 1.22 11.8914 1.28776V9.60592Z" fill="#03A8CD"/>
                    <path d="M9.15259 11.8911C9.15259 12.4332 9.36791 12.953 9.7512 13.3363C10.1345 13.7196 10.6543 13.9349 11.1964 13.9349C11.7384 13.9349 12.2582 13.7196 12.6415 13.3363C13.0248 12.953 13.2401 12.4332 13.2401 11.8911C13.2401 11.3491 13.0248 10.8292 12.6415 10.446C12.2582 10.0627 11.7384 9.84735 11.1964 9.84735C10.6543 9.84735 10.1345 10.0627 9.7512 10.446C9.36791 10.8292 9.15259 11.3491 9.15259 11.8911Z" fill="#C92121"/>
                    <path d="M11.1964 9.84837V13.9339C11.1751 13.9346 11.1538 13.9349 11.1325 13.9349C10.0391 13.9349 9.15259 13.0198 9.15259 11.8911C9.15259 10.7625 10.0391 9.84735 11.1325 9.84735C11.154 9.84735 11.1752 9.84761 11.1964 9.84837Z" fill="#F67A7A"/>
                    <path d="M6.78203 2.30951H11.3805V3.3314H6.78203V2.30951ZM2.94995 5.1197H11.1251V6.14159H2.94995V5.1197ZM2.94995 7.67442H11.1251V8.69631H2.94995V7.67442Z" fill="#12778D"/>
                    <path d="M3.20542 2.565H5.76014C5.93046 2.565 6.01561 2.65016 6.01561 2.82047V4.35331C6.01561 4.52362 5.93046 4.60878 5.76014 4.60878H3.20542C3.03511 4.60878 2.94995 4.52362 2.94995 4.35331V2.82047C2.94995 2.65016 3.03511 2.565 3.20542 2.565Z" fill="#E7BD56"/>
                    <path d="M4.48278 2.565V4.60878H3.20542C3.13767 4.60878 3.07269 4.58186 3.02478 4.53395C2.97687 4.48604 2.94995 4.42106 2.94995 4.35331V2.82047C2.94995 2.75272 2.97687 2.68774 3.02478 2.63983C3.07269 2.59192 3.13767 2.565 3.20542 2.565H4.48278Z" fill="#FFE4A3"/>
                    <path d="M10.6853 10.9969C10.6853 11.0807 10.7018 11.1638 10.7339 11.2413C10.766 11.3188 10.813 11.3892 10.8723 11.4485C10.9316 11.5079 11.0021 11.5549 11.0795 11.587C11.157 11.6191 11.2401 11.6356 11.324 11.6356C11.4079 11.6356 11.4909 11.6191 11.5684 11.587C11.6459 11.5549 11.7163 11.5079 11.7756 11.4485C11.8349 11.3892 11.882 11.3188 11.9141 11.2413C11.9462 11.1638 11.9627 11.0807 11.9627 10.9969C11.9627 10.913 11.9462 10.8299 11.9141 10.7524C11.882 10.6749 11.8349 10.6045 11.7756 10.5452C11.7163 10.4859 11.6459 10.4388 11.5684 10.4067C11.4909 10.3746 11.4079 10.3581 11.324 10.3581C11.2401 10.3581 11.157 10.3746 11.0795 10.4067C11.0021 10.4388 10.9316 10.4859 10.8723 10.5452C10.813 10.6045 10.766 10.6749 10.7339 10.7524C10.7018 10.8299 10.6853 10.913 10.6853 10.9969Z" fill="white"/>
                    <path d="M10.4315 13.1685C10.4306 13.1473 10.4302 13.126 10.4302 13.1047C10.4302 12.4346 10.8394 11.8912 11.3443 11.8912C11.8491 11.8912 12.2583 12.4346 12.2583 13.1047C12.2583 13.1261 12.2578 13.1473 12.2571 13.1685H10.4315Z" fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_4527_6766">
                      <rect width="14" height="14" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                <div>{{ item.from_user_name }} > {{ $t('todoCom.TodoList.me') }}</div>
              </div>
              <div class="footer-text" id="footer-text">
                <span
                  class="footer-span active"
                  :class="deadLine(item).class"
                  v-text="deadLine(item, item.endDate).time"
                ></span>
              </div>
            </div>
          </div>
          <!-- 3.0功能  置顶 暂时取消@廖伟 -->
          <!-- <div class="right-item-footer">
            <div class="footr-main">
              <div v-if="item.msg_type !== 3" class="footer-icon">
                <div>
                  <i
                    v-if="item.is_top"
                    class="iconfont iconzhiding active"
                    @click.stop="handleRomoveTop(item)"
                  ></i>
                  <i
                    v-else
                    class="iconfont iconzhiding hoverVisable"
                    @click.stop="handleSetTop(item)"
                  ></i>
                </div>
              </div>
              <div v-else class="footer-icon">
                <i style="visibility: hidden" class="iconfont iconzhiding"></i>
              </div>
            </div>
          </div> -->
        </li>
      </ul>
      <!-- <emptyCont v-else text="暂无内容!"/> -->
    </div>
    <div
      v-if="dataList && dataList.type == '-1'"
      class="list-view-main"
      style="height: calc(100% - 35px)"
    >
      <ul
        v-if="finishList && finishList.length"
        height="100%"
        style="height: 100%"
        class="scrollCont"
        :loading="false"
        v-infinite-scroll="load"
      >
        <li v-for="(el, index) in finishList" :key="index">
          <p class="time-filed">{{ el.timeFiled }}</p>
          <!-- 单项 -->
          <div
            class="list-view-item"
            v-for="(item, index) in getLocalizedData(el.data, [
              'title',
              'object_name',
            ])"
            :key="'taskitem' + index"
          >
            <div
              class="left-item-status"
              :style="getStausColor(item.status)"
            ></div>


            <div class="list-item-main" @click="handleItemClick(item)">
              <div class="item-number">
                {{ $t("todoCom.TodoList.785059-NO",[item.flow_approval_number??'-'])}}
              </div>
              <div class="item-main-top">
                <!-- 优先级 -->
                <span
                  v-if="item.priority && item.priority !== 0"
                  class="task-priority"
                  >{{ "!".repeat(item.priority) }} &nbsp;</span
                >
                <!-- 标题 -->
                <span class="header-title">
                  {{ item.t_title }}
                </span>
                &nbsp;
              </div>
              <div class="item-main-bottom">
                <div class="info-name">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <g clip-path="url(#clip0_4527_6766)">
                      <path d="M10.7234 8.63876C10.4774 8.55165 10.2125 8.50387 9.93682 8.50387C8.63365 8.50387 7.57702 9.56664 7.57702 10.8772C7.57702 11.4237 7.76045 11.9267 8.0688 12.3278H1.01548C0.947726 12.3278 0.882746 12.3009 0.834836 12.2529C0.786925 12.205 0.76001 12.1401 0.76001 12.0723V0.320596C0.76001 0.252841 0.786925 0.187861 0.834836 0.13995C0.882746 0.0920402 0.947726 0.0651245 1.01548 0.0651245L10.4679 0.0651245C10.5357 0.0651245 10.6007 0.0920402 10.6486 0.13995C10.6965 0.187861 10.7234 0.252841 10.7234 0.320596V8.63876Z" fill="#12778D"/>
                      <path d="M11.8914 9.60592C11.6454 9.51881 11.3804 9.47103 11.1048 9.47103C9.80162 9.47103 8.74499 10.5338 8.74499 11.8444C8.74499 12.3908 8.92842 12.8938 9.23677 13.2949H2.18345C2.1157 13.2949 2.05071 13.268 2.0028 13.2201C1.95489 13.1722 1.92798 13.1072 1.92798 13.0395V1.28776C1.92798 1.22 1.95489 1.15502 2.0028 1.10711C2.05071 1.0592 2.1157 1.03229 2.18345 1.03229H11.6359C11.7037 1.03229 11.7686 1.0592 11.8166 1.10711C11.8645 1.15502 11.8914 1.22 11.8914 1.28776V9.60592Z" fill="#03A8CD"/>
                      <path d="M9.15259 11.8911C9.15259 12.4332 9.36791 12.953 9.7512 13.3363C10.1345 13.7196 10.6543 13.9349 11.1964 13.9349C11.7384 13.9349 12.2582 13.7196 12.6415 13.3363C13.0248 12.953 13.2401 12.4332 13.2401 11.8911C13.2401 11.3491 13.0248 10.8292 12.6415 10.446C12.2582 10.0627 11.7384 9.84735 11.1964 9.84735C10.6543 9.84735 10.1345 10.0627 9.7512 10.446C9.36791 10.8292 9.15259 11.3491 9.15259 11.8911Z" fill="#C92121"/>
                      <path d="M11.1964 9.84837V13.9339C11.1751 13.9346 11.1538 13.9349 11.1325 13.9349C10.0391 13.9349 9.15259 13.0198 9.15259 11.8911C9.15259 10.7625 10.0391 9.84735 11.1325 9.84735C11.154 9.84735 11.1752 9.84761 11.1964 9.84837Z" fill="#F67A7A"/>
                      <path d="M6.78203 2.30951H11.3805V3.3314H6.78203V2.30951ZM2.94995 5.1197H11.1251V6.14159H2.94995V5.1197ZM2.94995 7.67442H11.1251V8.69631H2.94995V7.67442Z" fill="#12778D"/>
                      <path d="M3.20542 2.565H5.76014C5.93046 2.565 6.01561 2.65016 6.01561 2.82047V4.35331C6.01561 4.52362 5.93046 4.60878 5.76014 4.60878H3.20542C3.03511 4.60878 2.94995 4.52362 2.94995 4.35331V2.82047C2.94995 2.65016 3.03511 2.565 3.20542 2.565Z" fill="#E7BD56"/>
                      <path d="M4.48278 2.565V4.60878H3.20542C3.13767 4.60878 3.07269 4.58186 3.02478 4.53395C2.97687 4.48604 2.94995 4.42106 2.94995 4.35331V2.82047C2.94995 2.75272 2.97687 2.68774 3.02478 2.63983C3.07269 2.59192 3.13767 2.565 3.20542 2.565H4.48278Z" fill="#FFE4A3"/>
                      <path d="M10.6853 10.9969C10.6853 11.0807 10.7018 11.1638 10.7339 11.2413C10.766 11.3188 10.813 11.3892 10.8723 11.4485C10.9316 11.5079 11.0021 11.5549 11.0795 11.587C11.157 11.6191 11.2401 11.6356 11.324 11.6356C11.4079 11.6356 11.4909 11.6191 11.5684 11.587C11.6459 11.5549 11.7163 11.5079 11.7756 11.4485C11.8349 11.3892 11.882 11.3188 11.9141 11.2413C11.9462 11.1638 11.9627 11.0807 11.9627 10.9969C11.9627 10.913 11.9462 10.8299 11.9141 10.7524C11.882 10.6749 11.8349 10.6045 11.7756 10.5452C11.7163 10.4859 11.6459 10.4388 11.5684 10.4067C11.4909 10.3746 11.4079 10.3581 11.324 10.3581C11.2401 10.3581 11.157 10.3746 11.0795 10.4067C11.0021 10.4388 10.9316 10.4859 10.8723 10.5452C10.813 10.6045 10.766 10.6749 10.7339 10.7524C10.7018 10.8299 10.6853 10.913 10.6853 10.9969Z" fill="white"/>
                      <path d="M10.4315 13.1685C10.4306 13.1473 10.4302 13.126 10.4302 13.1047C10.4302 12.4346 10.8394 11.8912 11.3443 11.8912C11.8491 11.8912 12.2583 12.4346 12.2583 13.1047C12.2583 13.1261 12.2578 13.1473 12.2571 13.1685H10.4315Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_4527_6766">
                        <rect width="14" height="14" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <div>{{ item.from_user_name }} > {{ $t('todoCom.TodoList.me') }}</div>
                </div>
                <div class="footer-text" id="footer-text">
                  <span
                    class="footer-span active"
                    :class="deadLine(item).class"
                    v-text="deadLine(item, item.endDate).time"
                  ></span>
                </div>
              </div>
            </div>
               <!-- 3.0功能  置顶 暂时取消@廖伟 -->
            <!-- <div class="right-item-footer">
              <div class="footr-main">
                <div class="footer-icon"></div>
              </div>
            </div> -->
          </div>
        </li>
      </ul>
      <emptyCont v-else :text="$t('ListView.index.595688-1')" />
    </div>
    <div
      v-if="dataList && dataList.type == '-1'"
      style="text-align: right; padding: 10px 0"
    >
      <el-Pagination
        :current-page="page.page"
        :page-size="pageSize"
        @current-change="handlePageChange"
        :total="dataList.total"
        simple
      />
    </div>
  </div>
</template>

<script>
import { Pagination } from "element-ui";
import { format } from "date-fns";
import { getModulName } from "../modulAbout.js";
import { OverheadApi, cancelOverheadApi } from "@/apis/home";
import infiniteScroll from "vue-infinite-scroll";

export default {
  components: {
    //'el-button':Button,
    "el-Pagination": Pagination,
    //'el-input':Input,
  },
  directives: { infiniteScroll },
  mounted() {
    this.currentUserName = JSON.parse(
      localStorage.getItem("userInfo")
    )?.name?.replaceAll('"', "");
  },
  data() {
    return {
      // 迷你编辑器
      pageSize: 15,
      total: 21,
      miniEditor: false,
      // currenrUser: localStorage.getItem('userInfo').name.replaceAll('"', ''),
      // 新增数据
      // taskData: {
      //     archi_id: localStorage.getItem('archi').split('/')[2],
      //     name: '',
      //     task_type: 1,
      //     deadline: format(addDays(new Date(), 1), 'YYYY-MM-dd') + ' 23:59:59',
      //     priority: 1,
      //     trigger: 1,
      //     start_time: format(new Date(), 'YYYY-MM-dd HH:mm:ss'),
      //     receivers: [{
      //         user_id: localStorage.getItem('userId').replaceAll('"', ''),
      //         user_name:localStorage.getItem('userInfo').name.replaceAll('"', ''),
      //         role_id: JSON.parse(localStorage.getItem('thisRole')).id,
      //         role_name: JSON.parse(localStorage.getItem('thisRole')).name,
      //         type: 1
      //     }],
      //     approver: [{
      //         user_id: localStorage.getItem('userId').replaceAll('"', ''),
      //         role_id: JSON.parse(localStorage.getItem('thisRole')).id,
      //     }],
      //     module_name: 'assignment',
      //     status: 1,
      //     is_process: 1,
      //     permitted: 1,
      // },
      currentUserName: "",
      // 已完成
      finishList: [],
      loading: false,
      detailsShow: false,
    };
  },
  computed: {
    // 分页
    page() {
      return this.$store.getters.getFinishSearchParams;
    },
    dataList() {
      const data = this.$store.getters.getActiveItem || {};
      console.log(data);
      if (data.type === "-1") {
        // 处理未完成分页
        this.dealFinishList(data);
      }
      return data;
    },
  },
  methods: {
    load() {},
    handleActiveClick() {
      this.miniEditor = true;
      setTimeout(() => {
        this.$refs.input_task.focus();
      }, 10);
    },
    // inputTaskBlur() {
    //     if(this.taskData.name) {
    //         setTimeout(() => {
    //             this.detailsShow = false
    //         }, 200)
    //     } else {
    //         setTimeout(() => {
    //             this.miniEditor = false
    //         }, 200)
    //     }
    // },
    inputTaskFocus() {
      this.detailsShow = true;
    },
    //分页变化
    handlePageChange(v) {
      this.$store.commit("setFinishSearchParams", { size: 15, page: v });
    },
    // 处理未完成分页
    dealFinishList(data) {
      const arr = [];
      // 分割标志
      const splitFlag = "created_at";
      data.data.map((el) => {
        // 分割时间段
        const s = arr.find((item) => {
          return (
            item.timeFiled ===
            el[splitFlag].substring(0, 10).replaceAll("/", "-")
          );
        });
        if (s) {
          s.data.push(el);
        } else {
          arr.push({
            timeFiled: el[splitFlag].substring(0, 10).replaceAll("/", "-"),
            data: [el],
          });
        }
      });
      this.finishList = arr;
    },
    // 新增任务
    // addTask() {
    //     // console.log('回车新建事件', this.taskData)
    //     if(this.loading) {
    //         return
    //     }
    //     if(!(this.taskData.name)) {
    //         this.$Message.info('请填写任务标题')
    //         return
    //     }
    //     this.loading = true
    //     addTaskApi(this.taskData).then(res => {
    //         if (res.data.code === 200) {
    //             this.taskData.name = ''
    //             this.miniEditor = false
    //             this.$emit('refresh')
    //         }
    //     }).catch(err => {console.log(err)}).finally(() => {

    //         this.loading = false
    //     })
    // },
    // 获取前面状态的颜色
    getStausColor(status) {
      switch (status) {
        // 拒绝
        case 6: {
          return "background:#A4ACBD";
        }
        case 7: {
          // 作废
          return "background: #AFC1D1";
        }
        case -1: {
          //退回
          return "background: #FE6868";
        }
        case 0: {
          //发起
          return "background: #0ED0B9";
        }
        case 5: {
          //完成
          return "background: #43C684";
        }
        default: {
          //审核中
          return "background: #00ACCE";
        }
      }
    },
    // 获取状态的背景色和颜色
    getStatusLabeColor(status) {
      switch (status) {
        case 6: {
          // 拒绝
          return "background:rgba(164, 172, 189, 0.20);color:#A4ACBD";
        }
        case 7: {
          // 作废
          return "background: rgba(175, 193, 209, 0.20);color: #AFC1D1;";
        }
        case -1: {
          //退回
          return "background: rgba(254, 104, 104, 0.20); color:#FE6868 ;";
        }
        case 0: {
          //发起
          return "color: #0ED0B9;background: rgba(14, 208, 185, 0.20);";
        }
        case 5: {
          //完成
          return "background: rgba(67, 198, 132, 0.20);color:#43C684";
        }
        default: {
          //审核中
          return "background: rgba(0, 172, 206, 0.20); color: #00ACCE;";
        }
      }
    },
    // 获取状态文本
    getStutustext(status) {
      switch (status) {
        case 6: {
          return this.$t("ListView.index.595688-3");
        }
        case 7: {
          return this.$t("ListView.index.595688-4");
        }
        case -1: {
          //退回
          return this.$t("ListView.index.595688-5");
        }
        case 5: {
          //完成
          return this.$t("ListView.index.595688-2");
        }
        case 0: {
          //发起
          return this.$t("ListView.index.595688-6");
        }
        default: {
          return this.$t("ListView.index.595688-7");
        }
      }
    },
    // 格合化人名
    nameFormat(name) {
      if (name) {
        return name === this.currentUserName
          ? this.$t("ListView.index.595688-8")
          : name;
      } else {
        return "-";
      }
    },
    // 获取模块图标
    getModulIcon(modulName, objName) {
      return getModulName(modulName, objName).icon;
    },
    // 时间格式化
    deadLine(item) {
      // const deadLine = item.endDate;

      // let date1_s = new Date(deadLine.replace(/-/g, "/"));
      const targetDate = new Date(item.endDate);
      let a = targetDate.getTime()+(24 * 60 * 60 * 1000)
      let bTime = new Date()
      bTime.setTime(a)
      const nowTime = new Date();
      let dateDiff = nowTime.getTime() - targetDate.getTime();
      let date = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数

      if ([1, 4].includes(item.status)) {
        return {
          time: this.finishedTimeFormat(bTime),
          class: "gray-time",
        };
      }
      if (nowTime.getTime() > bTime && date) {
        return {
          time:
          
            this.$t("ListView.index.595688-99",[date]),
          class: "red-time",
          tag: true,
        };
      } else {
        return {
          time: this.timeFormat(bTime),
        };
      }
    },
    timeFormat(time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return this.$t("ListView.index.595688-11", [
              format(targetDate, "HH:mm"),
            ]);
          } else if (td - nd === 1) {
            return this.$t("ListView.index.595688-12", [
              format(targetDate, "MM-dd HH:mm"),
            ]);
          } else {
            return this.$t("ListView.index.595688-13", [
              format(targetDate, "MM-dd HH:mm"),
            ]);
          }
        } else {
          return this.$t("ListView.index.595688-13", [
            format(targetDate, "MM-dd HH:mm"),
          ]);
        }
      } else {
        return this.$t("ListView.index.595688-14", [
          format(targetDate, "yyyy-MM-dd HH:mm"),
        ]);
      }
    },
    finishedTimeFormat(time) {
      if (!this.nowDateObj) {
        this.nowDateObj = new Date();
      }
      const targetDate = new Date(time);
      const ny = this.nowDateObj.getFullYear();
      const ty = targetDate.getFullYear();
      const nm = this.nowDateObj.getMonth();
      const tm = targetDate.getMonth();
      const nd = this.nowDateObj.getDate();
      const td = targetDate.getDate();
      if (ny === ty) {
        if (nm === tm) {
          if (nd === td) {
            return this.$t("ListView.index.595688-15", [
              format(targetDate, "HH:mm"),
            ]);
          } else if (nd - td === 1) {
            return this.$t("ListView.index.595688-16", [
              format(targetDate, "HH:mm"),
            ]);
          } else {
            return `${format(targetDate, "MM-dd HH:mm")}`;
          }
        } else {
          return `${format(targetDate, "MM-dd HH:mm")}`;
        }
      } else {
        return `${format(targetDate, "yyyy-MM-dd HH:mm")}`;
      }
    },
    //全屏事件
    // handleFullScenc() {
    //     // console.log('接下来打开大编辑窗')
    //     this.$store.commit('setDrewerData', JSON.parse(JSON.stringify({ type: 'add', data : this.taskData})))
    //     this.$store.commit('setOpenDrawer', true)

    // },
    handleAdd() {
      // console.log('新建事件', this.taskData)
      this.addTask();
    },
    // 小项点击事件
    handleItemClick(item) {
      // 4.0 - 兼容处理：发送消息到引用页面
      // if(this.$checkIfVersionFour()){

      let data = {
        event: "showDetailModal",
        form_uuid: item.form_id,
        flow_id: item.flow_id,
        data_id: item.id,
        module_name: item.module_name,
        object_name: item.t_object_name,
        archi_type: item.archi_type,
      };

      if (item.task_engine_id) {
        let {
          form_type,
          func_form,
          task_recover_id,
          task_engine_id,
          task_log_id,
          object_uuid,
        } = item;
        const taskConfig = {
          form_type,
          func_form,
          task_recover_id,
          task_engine_id,
          task_log_id,
          object_uuid,
        };
        data.taskConfig = taskConfig;
      }

      window.parent.postMessage(data, "*");
      return;
      //  }
      // console.log('列表小项点击', item)
      // this.$store.commit('setActivetaskData', item)
    },
    // 置顶事件
    handleSetTop(item) {
      console.log('置顶', item)
      const data = {
        msg_type: item.msg_type,
        module_name: item.module_name,
        id: item.id,
      };
      OverheadApi(data).then((res) => {
        if (res.data.code === 200) {
          this.$store.commit("setRefesh");
        }
      });
    },
    // 取消置顶
    handleRomoveTop(item) {
      console.log('取消置顶', item)
      const data = {
        msg_type: item.msg_type,
        module_name: item.module_name,
        id: item.id,
      };
      cancelOverheadApi(data).then((res) => {
        if (res.data.code === 200) {
          this.$store.commit("setRefesh");
        }
      });
    },
    // 获取模块名
    getModulName(typeName, objName) {
      return getModulName(typeName, objName);
    },
  },
};
</script>

<style lang="less" scoped>
.list-view {
  height: 100%;
  .mini-task-editor {
    padding: 10px 0px;
    .mimi-editor-main {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
      .editor-mian {
        flex-grow: 1;
      }
    }
  }
  .list-view-main {
    overflow: auto;
    .time-filed {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #202126;
      line-height: 22px;
      padding: 10px 0;
    }
    .list-view-item {
      cursor: pointer;
      display: flex;
      height: 94px;
      padding: 0px;
      border-radius: var(--radius-4, 8px);
      border: 1px solid var(--border-on-surface-weak, #F0F1F4);
      background: linear-gradient(108deg, rgba(239, 255, 254, 0.00) 2.06%, rgba(255, 255, 255, 0.00) 44.9%);
      margin-bottom: 14px;
      position: relative;
      &:hover {
        background: rgba(0, 129, 153, 0.1);
        .hoverVisable {
          visibility: visible;
        }
      }
      .left-item-status {
        min-width: 6px;
        border-radius: 4px 0 0 4px;
        background: rgba(68, 149, 242, 1);
      }
      .list-item-main {
        padding: 12px 12px 10px 18px;
        flex-grow: 1;
        .item-number{
          overflow: hidden;
          color: var(--text-on-surface-placeholder, #A4ACBD);
          text-overflow: ellipsis;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; 
          letter-spacing: 0.4px;
          margin-bottom: 4px;
        }
        .item-main-top {
          margin-bottom: 4px;
          .task-priority {
            color: rgba(221, 44, 47, 1);
            font-size: 14px;
          }
          .header-title {
            color: var(--text-on-surface-primary, #181B22);
            /* web/cn/heading/heading-01 */
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 157.143% */
            letter-spacing: 0.5px;
          }
          .header-status {
            position: absolute;
            right: 0;
            top: 0;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 0px 8px;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 20px;
          }
          .task-finish {
            color: #2a953f;
            background: rgba(233, 244, 235, 1);
          }
        }
        .item-main-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .footer-text {
            text-align: right;

            .red-time {
              color: #CC4429 !important;
            }
          }
          #footer-text {
            .red-time {
              color: #CC4429 !important;
            }
            .gray-time {
              color: #707786 !important;
            }
          }
          .footer-span {
            color: var(--text-on-surface-tertiary, #707786);

            /* web/cn/helper/helper-03 */
            font-family: "PingFang SC";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: 0.4px;
          }
          .item-bottom-font {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: left;
            color: #7c808a;
            line-height: 20px;
          }
          .info-name{
            display: flex;
            align-items: center;
            div{
              color: var(--text-on-surface-tertiary, #707786);
              /* web/cn/helper/helper-03 */
              font-family: "PingFang SC";
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px; /* 153.846% */
              letter-spacing: 0.4px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
  .right-item-footer {
    display: flex;
    justify-items: center;
    // min-width: 120px;
    position: absolute;
    right:20px;
    top: -2px;
    .footr-main {
      margin: 0;
      width: 100%;
      padding: 5px 20px;
      .footer-icon {
        text-align: right;
      }
      .hoverVisable {
        visibility: hidden;
      }

    }
  }
}
</style>

<style lang="less">
.mimi-editor-main {
  #editor-mian {
    .ivu-input {
      border: none;
      &:focus {
        box-shadow: none;
        // border-bottom: 1px solid #f5f5f5;
      }
      &:hover {
        box-shadow: none;
        // border-color: #f5f5f5 !important;
        // border-bottom: 1px solid #f5f5f5 !important;
      }
      &:active {
        box-shadow: none;
        // border-bottom: 1px solid #f5f5f5;
      }
      &:visited {
        box-shadow: none;
        // border-bottom: 1px solid #f5f5f5;
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  .add-btn {
    border-radius: 6px !important;
    padding: 8px 8px !important;
    height: auto !important;
    background: rgba(82, 89, 102, 1) !important;
    color: #fff !important ;
    span {
      writing-mode: vertical-lr !important;
    }
  }
}
.list-view {
  .vxe-list--virtual-wrapper {
    &::-webkit-scrollbar {
      width: 6px;
      height: 8px;
      margin: 0 3px;
    }
    &::-webkit-scrollbar-track {
      background-color: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(189, 192, 199, 1);
      border-radius: 4px;
      /*border: 1px solid #F1F1F1;*/
      /*box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(189, 192, 199, 1);
    }
    &::-webkit-scrollbar-thumb:active {
      background-color: rgba(189, 192, 199, 1);
    }
  }
}
</style>
