<!--
 * @Description: 全天日程
 * @Author: luocheng
 * @Date: 2021-07-20 10:23:53
 * @LastEditors: lisushuang
 * @LastEditTime: 2022-04-12 14:38:48
-->
<template>
  <div class="all-day">
    <p class="label" v-if="!isWeek">{{ $t('dayView.AllDay.217772-0') }}</p>
    <ul class="list"
      :style="{
        maxHeight: viewHeight + 'px'
      }"
    >
      <!--  handleItemClick 小项点击事件 -->
      <li class="item" v-for="item in list" @click="handleItemClick(item)" :key="item.msg_type + 'allDay' + item.id"
        :class="[2, 5].includes(item.status) ? 'done' : 'unstart'"
      >
        {{ item.title }}
      </li>
    </ul>
    <div class="scroll-bar" v-if="list.length * 21 <= viewHeight"></div>
    <div class="boder-bottom" id="date-allday"
      @mousedown="onMousedown"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'AllDay',
  props: {
    // 是否为周视图
    isWeek: {
      type: Boolean,
      default: false,
      required: false
    },
    list: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // 全天日程列表(测试数据)
      // list: [
      //   {
      //     id: 1,
      //     status: 1, // 1 已完成 2 未完成
      //     title: '已完成1'
      //   },
      // ],
      // 初始距离顶部的坐标
      defaultY: 260,
      // 默认视图高度
      viewHeight: 92,
      mounseDown: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 抬起
      window.onmouseup = () => {
        this.mounseDown = false;
      };
      // 移动
      window.onmousemove = (event) => {
        if (!this.mounseDown) return;
        const { pageY } = event;
        if (pageY > window.innerHeight / 2) return; 
        if (pageY - this.defaultY < 0) {
          this.viewHeight = 92;
        } else {
          this.viewHeight = 92 + pageY - this.defaultY;
        }
     }
    });
  },
  methods: {
    /**
     * @desc: 按下鼠标
     * @param {*}
     * @return {*}
     */
    onMousedown(event) {
      const { pageY } = event;
      this.mounseDown = true;
      if (!this.defaultY) {
        this.defaultY = pageY;
      }
    },
    // 传递活动小项
    handleItemClick(item) {
      // 4.0 - 兼容处理：发送消息到引用页面
      //if(this.$checkIfVersionFour()){
        console.log(item,'item');
        let data = {
          event:'showDetailModal',
          form_uuid:item.form_id,
          flow_id:item.flow_id,
          data_id:item.id,
          module_name:item.module_name,
          object_name:item.object_name,
        }
        window.parent.postMessage(data,'*')
        return
      }
      //this.$store.commit('setActivetaskData', item)
   // }
  }
}
</script>

<style lang="less" scoped>
  @itemHeight: 92px;
.all-day{
  position: relative;
  width: 100%;
  display: flex;
  border-top: 1px solid #d9dce1;
  // border-bottom: 3px solid #d9dce1;
  padding-bottom: 3px;
  user-select: none;
  .label{
    width: 54px;
    margin-top: 1px;
    line-height: 20px;
    font-size: 12px;
    font-family: var(--systemFontFamily);
    font-weight: 500;
    text-align: center;
    color: #7c808a;
    line-height: 20px;
  }
  .list{
    flex: 1;
    height: auto;
    list-style: none;
    overflow: hidden;
    padding-right: 3px;
    max-height: @itemHeight;
    overflow: hidden;
    overflow-y: auto;
    transition: all .01s linear;
    &.is-week{
      border: none;
    }
    .item{
      box-sizing: border-box;
      width: 100%;
      height: 20px;
      padding: 0 12px;
      margin: 1px 0;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-family: var(--systemFontFamily);
      font-weight: 500;
      text-align: left;
      line-height: 20px;
      border-radius: 4px;
      cursor: pointer;
      &.unstart{
        background:  rgba(28,161,186,0.20);
        color: #008199;
      }
      &.done{
        background: rgba(215, 218, 224, 0.6);
        color: #8a8f99;
      }
      &:hover{
        color: #fff;
        &.done{
          background: #bdc0c7;
        }
        &.unstart{
          background: #1ca1ba;
        }
      }
    }
  }
  .scroll-bar{
    height: 100%;
    width: 15px;
    box-sizing: border-box;
    background: rgba(248, 249, 248, 1);
    border-left: 1px solid rgba(232, 232, 232, 1);
    border-right: 1px solid rgba(232, 232, 232, 1);
  }
  .boder-bottom{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    background: #d9dce1;
    width: 100%;
    cursor: pointer;
    &:hover{
      height: 10px;
    }
  }
}
</style>