<!--
 * @Description: 日历头部
 * @Author: luocheng
 * @Date: 2021-07-15 15:59:10
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-19 15:46:42
-->
<template>
  <div class="hedaer">
    <a href="javascript:;" class="btn" @click="onChange('prev')">
      <img src="@/assets/images/todos/prev.png" alt="">
    </a>
    <h3 class="label">{{ getFormatDate(year, month) }}</h3>
    <a href="javascript:;" class="btn" @click="onChange('next')">
      <img src="@/assets/images/todos/next.png" alt="">
    </a>
  </div>  
</template>

<script>
export default {
  name: 'CalendarHeader',
  props: {
    // 默认显示的年月 yyyy/MM
    defaultData: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      year: '',
      month: ''
    }
  },
  created() {
    this.initDate();
  },
  watch: {
    defaultData() {
      const newArr = this.defaultData.split('/');
      if (newArr.length > 1 && +newArr[0] === +this.year && +newArr[1] === +this.month) return;
      this.initDate();
    }
  },
  methods: {
    /**
     * @desc: 初始化数据
     * @param {*}
     * @return {*}
     */
    initDate() {
      let defaultArr = [];
      if (this.defaultData && this.defaultData.split('/').length > 1) {
        defaultArr = this.defaultData.split('/');
      }
      this.year = defaultArr.length > 1 && !isNaN(+defaultArr[0]) ? +defaultArr[0] : (new Date()).getFullYear();
      this.month = defaultArr.length > 1 && !isNaN(+defaultArr[1]) ? +defaultArr[1] : (new Date()).getMonth() + 1;
      this.$emit('updateDate', `${this.year}/${this.month}`);
    },
    /**
     * @desc: 切换月份
     * @param {*} type 类型  prev 上一个月 next 下一个月
     * @return {*}
     */
    onChange(type) {
      if (type === 'prev') {
        // 上个月
        if (this.month === 1) {
          // 一月需要变更年份
          if (this.year > 0) {
            this.year--;
            this.month = 12;
          }
        } else {
          this.month--;
        }
      } else if (type === 'next') {
        // 上个月
        if (this.month === 12) {
          this.month = 1;
          this.year++;
        } else {
          this.month++;
        }
      }
      this.$emit('updateDate', `${this.year}/${this.month}`);
    },
    getFormatDate(year, month) {
      return this.$i18n.locale === 'en' ? `${year}-${month}` : `${year}年${month}月`;
    }
  }
}
</script>

<style lang="less" scope>
@height: 16px;
@imgSize: 16px;
.hedaer{
  width: 100%;
  display: flex;
  height: @height;
  box-sizing: border-box;
  padding: 0 8px;
  .btn{
    box-sizing: border-box;
    padding: calc((@height - @imgSize)/2);
    img{
      display: block;
      height: @imgSize;
      width: @imgSize;
    }
  }
  .label{
    line-height: @height;
    flex: 1;
    text-align: center;
    padding: 0 20px;
    line-height: @height;
    box-sizing: border-box;
    font-size: 14px;
    font-family: var(--systemFontFamily);
    font-weight: 500;
    text-align: center;
    color: #525966;
  }
}
</style>