<!--
 * @Description: 内容区域主视图
 * @Author: luocheng
 * @Date: 2021-07-20 10:18:34
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-04-26 18:22:27
-->
<template>
  <div class="day-view">
    <h3 class="week-day" v-if="!isWeek">{{getWeekDay}}</h3>
    <article class="schedule-box" 
      :class="{
        'is-date-box': !isWeek
      }"
    >
      <AllDay v-if="!isWeek" :list="todoList.allDayList"></AllDay>
      <article class="schedule-main" ref="timeMain"
        :class="{
          'is-date': !isWeek
        }"
      >
        <ul class="table">
          <!-- 时间点 -->
          <template v-if="!isWeek">
            <li class="hour" v-for=" (item, index) in timeList" :key="index" :id="item.time"
              :class="{
                'is-target': item.isTarget,
                'on-hour': item.time.indexOf('00') !== -1
              }"
              :style="{
                top: item.isTarget ? (-1 * (60 - item.minutes) / 60) * 56 + 'px': 0
              }"
            >
              <p class="time" :style="{visibility: item.show ? '' : 'hidden'}" v-if="!isWeek">{{ item.time }}</p>
              <div class="area"></div>
            </li>
          </template>
          <!-- 中心区域 -->
          <article class="todo-box">
            <!-- 待办列表 -->
            <div v-for="(groupItem, gIndex) in groups" :key="gIndex" class="group"
              :style="{
                top: groupItem.top + 'px',
                height: groupItem.height < 20 ? '20px' : groupItem.height + 'px',
              }"
              :class="{
                'is-date-group': !isWeek
              }"
            >
              <section v-for="(child,index) in getLocalizedData(groupItem.children, ['title', 'object_name'])" @click="handleItemClick(child)" :key="child.msg_type + 'msgType' + index" class="child"
                :style="{
                  height: child.height < 20 ? '20px' : child.height +'px',
                  marginTop: child.top + 'px'
                }"
                :class="{
                  'done': [2, 5].includes(child.status),
                  'unfinish': ![2, 5].includes(child.status),
                  'hover-showmore': groupItem.children.length > 4 && !isWeek,
                  'is-week': isWeek
                }"
              >
                {{ child.t_title }}
                <p class="week-large" v-if="isWeek"
                  :style="{
                    height: child.height < 20 ? '20px' : child.height +'px',
                    marginTop: child.top + 'px',
                  }"
                  :class="{
                    'done': [2, 5].includes(child.status),
                    'unfinish': ![2, 5].includes(child.status),
                  }"
                >{{ child.t_title }}</p>
              </section>
            </div>
          </article>
        </ul>
      </article>
    </article>
  </div>
</template>

<script>
import AllDay from './AllDay';

export default {
  name: 'DayView',
  components: {
    AllDay
  },
  props: {
    // 是否为周视图
    isWeek: {
      type: Boolean,
      default: false,
      required: false
    },
    // 当为周视图是否为第一周
    isFirstDay: {
      type: Boolean,
      default: true,
      required: false
    },
    todoList: {
      type: Object,
      default() {
        return {
          data: [],
          allDayList: []
        }
      }
    },
    time: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 时间刻度表
      timeList: [],
      // 事件列表 mock
      date: '2021/7/20',
      // 分组
      groups: [],
      // 每小时模块高度用于计算模块高度
      HOURHEIGHT: 57
    }
  },
  computed: {
    getWeekDay() {
      const times = this.time
      const date = new Date()
      date.setTime(times)
      const i = date.getDay()
      const temp = this.$i18n.locale === 'en'? ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : ['星期天','星期一','星期二','星期三','星期四','星期五','星期六']
      return temp[i]
    }
  },
  watch: {
    todoList: {
      handler(v) {
        // 渲染
        if(v && Array.isArray(v.data)) {
          // 如果没有数据清空日程表
          if(v.data.length === 0) {
            this.groups = []
          } else {
            this.formatData(JSON.parse(JSON.stringify(v.data)));
          }
          // 更新视图后回到八点
          this.$nextTick(() => {
            if (this.$refs.timeMain) {
              this.$refs.timeMain.scrollTop = 8 * this.HOURHEIGHT;
            }
          });
        }
      },
      immediate: true
    }
  },
  created() {
    this.initTable();
    // this.formatData(this.todoList);
  },
  mounted() {
    this.$nextTick(() => {
      // 第一视角跳转八点
      if (this.$refs.timeMain) {
        this.$refs.timeMain.scrollTop = 8 * this.HOURHEIGHT;
      }
    });
  },
  methods: {
    /**
     * @desc: 初始化table 时间刻度 当前时间
     * @param {*}
     * @return {*}
     */
    initTable() {
      this.timeList = [];
      const now = new Date();
      const hour = now.getHours();
      const minutes = now.getMinutes();
      let targetIndex = 0
      for(let i = 0; i <= 24; i++){
        this.timeList.push({
          time: i < 10 ? `0${i}:00` : `${i}:00`,
          isTarget: hour === i && minutes === 0,
          hour: i,
          minutes: 0,
          show: true
        });
        if (hour === i && minutes !== 0) {
          this.timeList.push({
            time: `${hour < 10 ? '0' + hour : hour}:${minutes < 10 ? '0' + minutes : minutes}`,
            isTarget: true,
            hour: i,
            minutes,
            show: true
          });
          targetIndex = i
        }
      }
      if(minutes < 20) {
        this.timeList[targetIndex].show = false
      } else if(minutes > 30) {
        this.timeList[targetIndex + 2].show = false
      }
      // console.log(this.timeList, '-this.timeList')
    },
    /**
     * @desc: 格式化数据
     * @param {*}
     * @return {*}
     */
    formatData(todos = []) {
      if (!todos || !Array.isArray(todos) || !todos.length) return [];
      // 先按时序排序
      let todoList = todos.sort((chilOne, childTwo) => {
        const ss = this.getTimestamp(chilOne.satrtTime);
        const es = this.getTimestamp(childTwo.satrtTime);
        return ss - es;
      });
      // 将数组按时间跨度度从长到端排序
      todoList = todoList.sort((chilOne, childTwo) => {
        const betweenOne = this.getTimestamp(chilOne.endTime) - this.getTimestamp(chilOne.satrtTime);
        const betweenTwo = this.getTimestamp(childTwo.endTime) - this.getTimestamp(childTwo.satrtTime);
        return betweenTwo - betweenOne;
      });
      // 首先分块，将互相时间节点有重叠的待办划归一组
      this.groups = [];
      const satrtItem = todoList[0];
      todoList.splice(0, 1)
      this.getIntersection(satrtItem, todoList)
      // console.log(this.groups, '--groups')
    },
    /**
     * @desc:  寻找是否存在交集
     * @param {*} item 当前待办
     * @param {*} arr 待办列表
     * @return {*}
     */
    getIntersection(item, arr = []) {
      const itemStart = this.getTimestamp(item.satrtTime);
      const itemEnd = this.getTimestamp(item.endTime);
      const lessArr = JSON.parse(JSON.stringify(arr)); // 去除交集后剩余的待办列表
      const children = [];
      children.push({
        ...item,
        height: ((itemEnd - itemStart) / 3600000) * this.HOURHEIGHT , // 时间跨度对应高度
      });
      // 获取交集列表
      arr.map((ele) => {
        const eleStart = this.getTimestamp(ele.satrtTime);
        const eleEnd = this.getTimestamp(ele.endTime);
        // if (!(itemEnd < eleStart || itemStart > eleEnd)) {
        // 扩大范围、待办的时间区间较小
        // 小时区间3600000，高度57px，文本高度20，保证待办20px内不重叠，那就是3600000 / 57 * 20 * 2 = 1263157
        if(Math.abs(itemStart - eleStart) < 1263157 || Math.abs(itemEnd - eleEnd) < 1263157 * 2){
          // 存在交集 或 则为 子集
          // console.log(ele, '-存在交集', index);
          children.push({
            ...ele,
            height: ((eleEnd - eleStart) / 3600000) * this.HOURHEIGHT , // 时间跨度对应高度
          });
          // 删除数据
          for (let i = 0; i < lessArr.length; i++) {
            if (ele.id === lessArr[i].id) {
              lessArr.splice(i, 1);
              break;
            }
          }
        }
      });
      // 获取当前组相对于0点的高度
      let minTime = children[0].satrtTime;
      let maxTime = children[0].endTime;
      children.forEach(child => {
        if (this.getTimestamp(child.satrtTime) < this.getTimestamp(minTime)) {
          minTime = child.satrtTime;
        }
        if (this.getTimestamp(child.endTime) > this.getTimestamp(maxTime)) {
          maxTime = child.endTime;
        }
      });
      // 子元素宽高
      for (let i = 0; i < children.length; i++) {
        children.splice(i, 1, {
          ...children[i],
          top: ((this.getTimestamp(children[i].satrtTime) - this.getTimestamp(minTime))) / 3600000 * this.HOURHEIGHT
        });
      }
      // 组
      const result = {
        children,
        height: ((this.getTimestamp(maxTime) - this.getTimestamp(minTime))) / 3600000 * this.HOURHEIGHT,
        top: ((this.getTimestamp(minTime) - this.getTimestamp('00:00'))) / 3600000 * this.HOURHEIGHT
      }
      this.groups.push(result);
      if (lessArr.length) {
        const satrtItem = lessArr[0];
        lessArr.splice(0, 1)
        this.getIntersection(satrtItem, lessArr);
      }
    },
    /**
     * @desc: 根据时间点获取时间戳
     * @param {*} time 时间点 eg： 09:00
     * @return {*}
     */
    getTimestamp(time) {
      return new Date(`${this.date} ${time}`).getTime();
    },
    // 传递活动小项
    handleItemClick(item) {
      // 4.0 - 兼容处理：发送消息到引用页面
     // if(this.$checkIfVersionFour()){
        let data = {
          event:'showDetailModal',
          form_uuid:item.form_id,
          flow_id:item.flow_id,
          data_id:item.id,
          module_name:item.module_name,
          object_name:item.t_object_name,
        }
        window.parent.postMessage(data,'*')
        return
     // }
     // this.$store.commit('setActivetaskData', item)
    }
  }
}
</script>

<style lang="less" scoped>
@areaHeight: 57px;
@tagetColor: #cc4429;
@timeWidth: 54px;
.day-view{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  .week-day{
    width: 100%;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #525966;
    line-height: 22px;
  }
  .schedule-box{
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &.is-date-box{
      overflow: hidden;
    }
    .schedule-main{
      box-sizing: border-box;
      padding: 10px 0;
      &.is-date{
        overflow: hidden;
        overflow-y: auto;
      }
      .table{
        flex: 1;
        width: 100%;
        list-style: none;
        position: relative;
        .hour{
          width: 100%;
          display: flex;
          height: @areaHeight;
          &:last-of-type{
            height: 0;
            .area{
              height: 0;
            }
          }
          &.is-target{
            height: 0;
            position: relative;
            &.on-hour{
              height: @areaHeight;
              top: 0;
              position: relative;
              .area{
                height: @areaHeight;
              }
            }
            .time{
              color: @tagetColor;
            }
            .area{
              height: 0;
              border-top: 1px solid @tagetColor;
              &:before{
                content: '';
                display: block;
                height: 10px;
                width: 10px;
                position: absolute;
                top: -5px;
                border-radius: 5px;
                background: @tagetColor;
              }
            }
          }
          .time{
            width: @timeWidth;
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: right;
            color: #7c808a;
            line-height: 12px;
            box-sizing: border-box;
            padding-right: 12px;
            text-align: right;
            margin-top: -6px;
          }
          .area{
            flex: 1;
            height: @areaHeight;
            box-sizing: border-box;
            border-top: 1px solid #d7dae0;
            background: #fff;
          }
        }
        // 待办
        .todo-box{
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          padding-left: @timeWidth;
          background: transparent;
          .group{
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            cursor: pointer;
            &.is-date-group{
              width: calc(~"100%" - @timeWidth + 5px);
              left: @timeWidth;
            }
            .child{
              flex: 1;
              border-radius: 4px;
              overflow: hidden;
              box-sizing: border-box;
              padding-left: 12px;
              text-align: left;
              margin-right: 1px;
              font-size: 12px;
              font-family: var(--systemFontFamily);
              font-weight: 500;
              // line-height: 20px;
              line-height: 18px;
              border-width: 0;
              border-style: solid;
              cursor: pointer;
              word-break: break-all;
              overflow: hidden;
              padding: 2px 0 2px 12px;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              &:last-of-type{
                margin: 0;
              }
              &:first-of-type {
                border-left-width: 3px;
              }
              &.hover-showmore{
                &:hover{
                  flex-basis: 40%;
                }
              }
              &.unfinish{
                background: rgba(28,161,186,0.20);
                border-color: #1ca1ba;
                color: #008199;
                &:hover{
                  background: #1ca1ba;
                  color: #fff;
                }
              }
              &.done{
                background: rgba(215,218,224,0.60);
                border-color: #bdc0c7;
                color: #8a8f99;
                &:hover{
                  background: #bdc0c7;
                  color: #fff;
                }
              }
              .week-large{
                position: absolute;
                // height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: none;
                padding: 2px 0 2px 12px;
                &.unfinish{
                  background: #479dff;
                  border-left: 3px solid #479dff;
                  color: #fff;
                }
                &.done{
                  background: rgba(215,218,224,1);
                  border-left: 3px solid rgba(215,218,224,1);
                  color: #fff;
                }
              }
              // 周视图下
              &.is-week{
                transition: width .1s linear;
                padding: 0 4px 0 4px;
                // 不能影藏 有显示BUG
                // overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                &.done{
                  // background: rgba(198,220,245,0.60);
                  // border-color: #479dff;
                  // color: #116fd9;
                  // border-left: 3px solid #479dff;
                }
                &:hover{
                  .week-large{
                    display: block;
                    position: absolute;
                    border-radius: 3px;
                    top: 0;
                    left: 0;
                    width: 100%;
                    min-height: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>