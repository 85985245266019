<template>
    <div class="task-details">
        <div v-if="!loading" class="task-details-header">
            <div>
                <span class="header-tap-name active" :class="active === 'details'? 'tap-active' : ''" style="margin-right: 10px" @click="setActive('details')">详情</span>
                <span class="header-tap-name active" :class="active === 'operation'? 'tap-active' : ''" @click="setActive('operation')">审批</span>
            </div>
            <div>
                <i v-if="archi_id == infoData.archi_id" class="iconfont iconwangpan-bianji1x icon" style="margin-right: 10px" @click="editorTask"></i>
                <el-popconfirm
                    v-if="archi_id == infoData.archi_id && [0, -1].includes(infoData.process_status) && infoData.status == 1"
                    confirm
                    title="删除后不可恢复，确认删除吗？"
                    @on-ok="handleDelete">
                    <i class="iconfont iconwangpan-shanchu1x icon"></i>
                </el-popconfirm>
            </div>
        </div>
        <div v-if="!loading" class="task-details-main">
            <DetailsView :infoData="infoData" v-if="active === 'details'"></DetailsView>
            <OperationView @refresh="getTaskDetails" :infoData="infoData" v-if="active === 'operation'"></OperationView>
            <div style="height: 0; width: 0; overflow: hidden;">
                {{ drwaerData }}
            </div>
        </div>
        <div v-if="loading" class="loding-div">
        <div class="active"  v-loading='loadingFlag' style="text-align: center">
          <!-- <p>加载中....</p> -->
        </div>
      </div>
    </div>
</template>

<script>

import { workPlanDetlies } from '@/apis/objectivesRespon'
import { deleteTaskApi } from '@/apis/home'
import DetailsView from './Details'
import {Popconfirm} from 'element-ui'
import OperationView from './Operation'
//import treeVue from '../../../../../components/spreadsheet/components/tree/tree.vue'
export default {
    components: { DetailsView, OperationView,'el-popconfirm':Popconfirm, },
    props: {
        taskData: {
            type: Object,
            reqire: true
        }
    },
    data() {
        return {
            active: 'details',
            loading: false,
            infoData: {},
            baseData: {},
            archi_id: localStorage.getItem('archi').split('/')[2]
        }
    },
    mounted(){
         const data = this.taskData
            this.baseData = JSON.parse(JSON.stringify(data))
            if (data.id) {
                this.getTaskDetails(data.id)
            }
    },
    computed: {
        drwaerData() {
            const data = this.taskData
           
            return data
        }
    },
    methods: {
        editorTask() {
            const data = {
                ...this.baseData,
                type: 'editor',
                justBack: true,
                infoData: this.infoData
            }
            this.$store.commit('setDrewerData', data)
            //this.$store.commit('setOpenDrawer', treeVue)
        },
        getTaskDetails(id) {
            this.loading = true
            workPlanDetlies(id).then(res => {
                if (res.data.code === 200) {
                    this.infoData = res.data.data
                } else {
                    this.$Message.error('此条数据无效！')
                    setTimeout(() => {this.$emit('back')}, 1000)
                }
            }).catch( ()=> {
                
                this.$Message.error('此条数据无效！')
                setTimeout(() => {this.$emit('back')}, 1000)
            }).finally(() => {
                this.loading = false
            })
        },
        setActive(tap) {
            this.active = tap
        },
        handleDelete() {
            // this.$emit('deleteEvt')
            if(this.loading) {
                return 
            }
            if (this.infoData.id) {
                this.loading = true
                deleteTaskApi(this.infoData.id).finally(() => {
                    this.loading = false
                })
            } else {
                this.$Message.info('当前任务不可删除')
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .task-details{
        height: 100%;
        width: 100%;
        .task-details-header{
            background: #fff;
            padding: 8px;
            display: flex;
            border-bottom: 1px solid #eee;
            justify-content: space-between;
            .header-tap-name{
                cursor:pointer;
                padding: 4px;
                font-size: 14px;
                font-family: var(--systemFontFamily);
                font-weight: 500;
                text-align: left;
                color: rgba(138,143,153,1);
                line-height: 22px;
            }
            .tap-active{
                color: rgba(37,40,46,1);
                border-bottom: 3px solid rgba(0,129,153,1);
            }
            .icon{
                cursor:pointer;
                font-size: 16px;
                line-height: 22px;
            }
        }
        .task-details-main{
            height: calc(~"100% - 40px");
        }
        .loading-div{
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
            @keyframes reateIcon {
                0% {transform: rotate(0deg);}
                100% {transform: rotate(360deg)}
            }
            .reate-icon{
                animation-name: reateIcon;
                animation-duration: 1s;
                animation-iteration-count: infinite;
            }
        }
    }
</style>