<template>
    <div class="operation-view">
        <div class="operation-main">
            <div v-if="Array.isArray(infoData.logs) && infoData.logs.length !== 0 && infoData.type === 2" style="height: 100%">
                <el-scroll>
                    <template v-for="(item, index) in infoData.logs">
                        <div :key="'operation' + index" class="operation-item">
                            <div class="operation-header">
                                <div class="flex-box">
                                    <img class="head-portrait" :src="item.ffff || 'https://dwh_api.bimcc.net/uploads/202005/26/1590477587_iQZw1zzWJF_thumb.png'"/>
                                    <span class="head-name" style="margin-left: 12px;">{{ item.user_name }}</span>
                                    <span class="head-status" :class="statusLabelFormat(item.status)" style="margin-left: 12px;">{{ statusFormat(item.status) }}</span>
                                </div>
                                <div class="flex-box">
                                    {{ timeFormat(item.created_at) }}
                                </div>
                            </div>
                            <div class="operation-content">
                                <p class="text-content">
                                    {{item.content}}
                                </p>
                                <DragFiles disabled :showFooter="false" :showHeader="false" :files="Array.isArray(item.file) ? item.file : []"/>
                            </div>
                        </div>
                    </template>
                </el-scroll>
            </div>
            <div v-else-if="Array.isArray(infoData.logs2) && infoData.logs2.length !== 0 && infoData.type === 3" style="height: 100%">
                <el-scroll>
                    <template v-for="(item, index) in infoData.logs2">
                        <div :key="'operation' + index" class="operation-item">
                            <div class="operation-header">
                                <div class="flex-box">
                                    <img class="head-portrait" :src="item.ffff || 'https://dwh_api.bimcc.net/uploads/202005/26/1590477587_iQZw1zzWJF_thumb.png'"/>
                                    <span class="head-name" style="margin-left: 12px;">{{ item.approver }}</span>
                                    <span class="head-status" :class="statusLabelFormat(item.status)" style="margin-left: 12px;">{{ item.process_name }}</span>
                                </div>
                                <div class="flex-box">
                                    {{ timeFormat(item.created_at || item.dateTime) }}
                                </div>
                            </div>
                            <div class="operation-content">
                                <p class="text-content">
                                    {{item.note}}
                                </p>
                                <DragFiles disabled :showFooter="false" :showHeader="false" :files="Array.isArray(item.img) ? item.img : []"/>
                            </div>
                        </div>
                    </template>
                </el-scroll>
            </div>
            <emptyCont v-else text="暂无内容!"/>
        </div>
        <!-- 当且仅当未完成、当前为责任人时展示 -->
        <div v-if="archi_id != infoData.archi_id" class="operation-footer">
            <p style="text-align: center">该任务不属于本架构，仅可进行查看</p>     
        </div>
        <div v-else-if="infoData.status === 3 && handleUserinR()" class="operation-footer">
            <p style="text-align: center"><i class="iconfont iconwangpan-duigou1x" style="color: #2a953f;"></i> &nbsp;审批已完成</p>     
        </div>
        <div v-else-if="infoData.is_process === 0 && infoData.type === 3 && handleUserinR() && infoData.operate && infoData.operate.length" class="operation-footer">
            <p style="text-align: center">流程未触发，暂无审批操作</p>     
        </div>
        <div v-else-if="infoData.status !== 3 && handleUserinR()" class="operation-footer">
            <Feedback :infoData="infoData" @refresh="refresh" />
        </div>
        <div v-else class="operation-footer">
            <p style="text-align: center">您当前无审批权限</p>     
        </div>
    </div>
</template>

<script>
import DragFiles from '../FileUpload.vue'
import Feedback from './Feedback'
import { format } from "date-fns";
import { Scrollbar } from 'element-ui'
export default {
    components: {
        DragFiles,
        Feedback , 
        'el-scroll':Scrollbar
        },
    props: {
        infoData: {
            type: Object,
            default() {
                return{ }
            }
        }
    },
    data() {
        return {
            conOpen: false,
            user_id: localStorage.getItem('userId'),
            archi_id: localStorage.getItem('archi').split('/')[2]
        }
    },
    methods: {
        // 当前用户是否为责任人
        handleUserinR() {
            return this.infoData.receivers.some(el => el.user_id == this.user_id)
        },
        timeFormat(time) {
            if (!time) {
                return '-'
            }
            if (!this.nowDateObj) {
                this.nowDateObj = new Date()
            }
            const targetDate = new Date(time)
            const ny = this.nowDateObj.getFullYear()
            const ty = targetDate.getFullYear()
            const nm = this.nowDateObj.getMonth()
            const tm = targetDate.getMonth()
            const nd = this.nowDateObj.getDate()
            const td = targetDate.getDate()
            if (ny === ty) {
                if (nm === tm) {
                    if (nd === td) {
                        return `${format(targetDate, 'HH:mm')}`
                    } else {
                        return `${format(targetDate, 'MM-dd HH:mm')}`
                    }
                } else {
                    return `${format(targetDate, 'MM-dd HH:mm')}`
                }
            } else {
                return `${format(targetDate, 'yyyy-MM-dd HH:mm')}`
            }
        },
        statusLabelFormat(status) {
            if (this.infoData.type === 2) {
                switch(status) {
                    case 2: {
                        return 'task-unfinish'
                    }
                    case 3: {
                        return 'task-finish'
                    }
                }
            } else {
                switch(status) {
                    case -1: {
                        return 'task-unfinish'
                    }
                    case 0: {
                        return ''
                    }
                    case 1: {
                        return 'task-finish'
                    }
                }
            }
        },
        statusFormat(status) {
            if (this.infoData.type === 2) {
                switch(status) {
                    case 2: {
                        return '未完成'
                    }
                    case 3: {
                        return '已完成'
                    }
                }
            }
        },
        refresh() {
            this.$emit('refresh', this.infoData.id)
        }
    }
}
</script>

<style lang="less" scoped>
    .operation-view{
        height: 100%;
        .operation-main{
            height: calc(~"100% - 60px");
        }
        .operation-item{
            background: #fff;
            padding: 16px;
            margin-bottom: 12px;
            .operation-header{
                .flex-box{
                    display: flex;
                    align-items: center;
                }
                display: flex;
                justify-content: space-between;
                .head-portrait{
                    width: 30px;
                    height: 30px;
                    border-radius: 3px;
                }
                .head-name{
                    font-size: 14px;
                    font-family: var(--systemFontFamily);
                    font-weight: 500;
                    text-align: justify;
                    color: #202126;
                    line-height: 22px;
                }
                .head-status{
                    padding: 2px 8px;
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: left;
                    line-height: 20px;
                    border-radius: 10px;
                }
                .task-finish{
                    color: #2a953f;
                    background: rgba(233,244,235,1);
                }
                .task-unfinish{
                    background: #f9ece9;
                    color: rgba(204,68,41,1);
                }
            }
            .operation-content{
                padding: 4px 4px 4px 44px;
                .text-content{
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: justify;
                    color: #202126;
                    line-height: 20px;
                }
            }
        }
        .operation-footer{
            box-shadow: -2px 0 5px #ddd;
            background: #f5f5f5;
            position: fixed;
            right: 0;
            bottom: 0;
            width: 323px;
            margin: 0 16px 0 12px;
            padding: 10px;
        }
    }
</style>