<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: hw 315249041@qq.com
 * @LastEditTime: 2024-03-19 16:07:03
-->
<template>
	<div
		class="home-main"
		v-loading="loading"
	>
		<Aside
			:calendarType="calendarType"
			:defaultDate="+currentDate"
		>
		</Aside>
		<!-- 内容区 -->
		<MainBox
			@getType="onGetType"
			@dateChange="onDate"
			@searchParamsChange="searchParamsChange"
		></MainBox>
		<!-- 右侧待办列表或详情 -->
		<Details v-show="calendarType === 'date'">
		</Details>
	</div>
</template>

<script>
import { getRenderData } from '@/apis/data/index';
import { isJSONStr } from '@/utils/tools';
import Aside from '@/components/todoList/Aside';
import MainBox from '@/components/todoList/MainBox';
import Details from '@/components/todoList/Details';
import { mapState } from 'vuex';
import { getNewHomeList } from '@/apis/home';

export default {
	name: 'newHome',
	components: {
		Aside,
		MainBox,
		Details
	},
	props: {
		pageUUID: {
			type: String,
			required: false,
			default: ''
		}
	},
	data() {
		return {
			inited: true,
			loading: false,
			showDetails: false,
			// 当前所选日期
			currentDate: '',
			// 类型
			calendarType: 'list',
			// 右侧抽屉
			rightDrawer: false,
			// 列表数据 及当前活动项数据
			taskListBase: []
		};
	},
	computed: {
		...mapState(['targetGlobalTab', 'systemConfig', 'currentApp']),
		// 根据当前点击的顶部菜单判断是否内容区全屏显示
		isFullScreen() {
			const FULLTABS = ['dataview'];
			return !this.targetGlobalTab || FULLTABS.includes(this.targetGlobalTab);
		},
	},
	created() {
		this.getViewData();
	},
	watch: {
		$route() {
			this.reRender();
		}
	},
	mounted() {
		this.isUpdatePwd = localStorage.getItem('updatePwd') == '0' && this.systemConfig?.force_password
    if(this.isUpdatePwd) {
      this.$router.replace({
        name: 'PedestalUserCenter'
      });
      return 
    }
		// 获取任务列表
		this.getTaskList();
	},
	methods: {
		// 请求所有任务列表

		getTaskList() {
			// loding效果
			const list = [
				{
					icon: 'iconjijia_dangqian',
					title: '全部',
          enTitle: 'All',
					type: '0',
					num: 0,
					data: []
				},
				{
					icon: 'icondaichuli',
					title: '待处理',
          enTitle: 'Todo',
					type: '1',
					num: 0,
					data: []
				},
				{
					icon: 'iconfaqi',
					title: '我发起的',
          enTitle: 'Started by me',
					type: '2',
					num: 0,
					data: []
				},
				{
					icon: 'iconchaosong',
					title: '抄送给我',
          enTitle: 'Copied to me',
					type: '3',
					num: 0,
					data: []
				},
				{
					icon: 'iconyiwancheng',
					title: '已处理',
          enTitle: 'Resolved',
					type: '-1',
					data: [],
					num: 0
				}
			];
			this.$store.commit('setIndexMenuList', list);
			this.$store.commit('setSearchLoading', true);
			let params = null
			if (this.currentApp?.app_orign_data?.[0]?.children_list?.length) {
				const list = this.currentApp.app_orign_data[0].children_list
				let objects = []
				const loop = (arr) => {
					arr.forEach(element => {
						if (element?.relation_objects?.length) {
							objects = objects.concat(element.relation_objects)
						}
						if (element?.children_list?.length) {
							loop(element.children_list)
						}
					});
				}
				if(list?.length) {
					loop(list)
				}
				params = {
					relation_objects: Array.from(new Set(objects))
				}
			}
			getNewHomeList(params ? params : {})
				.then((res) => {
					if (res.data.code === 200) {
						const data = res.data.data;
						// 进行筛选
						const list = [
							{
								icon: 'iconjijia_dangqian',
								title: '全部',
								enTitle: 'All',
								type: '0',
								num: 0,
								data: []
							},
							{
								icon: 'icondaichuli',
								title: '待处理',
								enTitle: 'Todo',
								type: '1',
								num: 0,
								data: []
							},
							{
								icon: 'iconfaqi',
								title: '我发起的',
								enTitle: 'Started by me',
								type: '2',
								num: 0,
								data: []
							},
							{
								icon: 'iconchaosong',
								title: '抄送给我',
								enTitle: 'Copied to me',
								type: '3',
								num: 0,
								data: []
							},
							{
								icon: 'iconyiwancheng',
								title: '已处理',
								enTitle: 'Resolved',
								data: [],
								type: '-1',
								num: 0
							}
						];
						// 如果不是数组停止
						if (!Array.isArray(data)) {
							return;
						}
						// 处理统计
						const staticArr = [];
						list[0].data = data;
						data.map((el, index) => {
							switch (el.msg_type) {
								case 1: {
									list[1].data.push(el);
									break;
								}
								case 2: {
									list[2].data.push(el);
									break;
								}
								case 3: {
									list[3].data.push(el);
									break;
								}
							}
							const s = staticArr.find((item) => {
								return item.date === el.startDate.substring(0, 10);
							});
							if (s) {
								s.todos.push({
									todoId: el.id,
									done: [2, 5].includes(el.status) ? true : false
								});
							} else {
								staticArr.push({
									id: index,
									date: el.startDate.substring(0, 10),
									todos: [
										{
											todoId: el.id,
											done: [2, 5].includes(el.status) ? true : false
										}
									]
								});
							}
						});
						list.map((el) => {
							el.num = el.data.length;
							el.allDayList = el.data.filter((el) => el.allDayList === 1);
						});
						this.taskListBase = list;
						// 存入vuex 方便各方组件跨层级调用
						// 统计
						this.$store.commit('setTodoList', staticArr);
						// 菜单
						this.$store.commit('setIndexMenuList', list);
						// 活动列表
						this.$store.commit('setActiveItem', list[0]);
						// 菜单活动项
						this.$store.commit('setActiveItemIndex', 0);
					}
				})
				.catch(() => {})
				.finally(() => {
					this.$store.commit('setSearchLoading', false);
				});
		},
		searchParamsChange(param) {
			// 暂时没有使用
			console.log(param);
		},
		/**
		 * @desc: 选择时间
		 * @param {*} times
		 * @return {*}
		 */
		onDate(times) {
			this.currentDate = times;
		},
		/**
		 * @desc: 获取当前所选视图类型
		 * @param {*} type date 日 week 周 month 月
		 * @return {*}
		 */
		onGetType(type) {
			this.calendarType = type;
			if (type === 'list') return;
		},
		/**
		 * @desc: 获取dataview配置数据
		 */
		getViewData() {
			this.loading = true;
			getRenderData({
				// __method_name__: 'dataInfo',
				// object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				// view_uuid: '175c6fe7-c8a2-4530-99be-80a8f1113f3c',
				// data_id: this.$route.query.pageId,
				__method_name__: 'dataList',
				page_uuid: this.pageUUID || this.$route.query.pageUUID,
				object_uuid: 'a4f016d6-c602-4492-8874-f088c3c0b3b9',
				view_uuid: 'view61b951c6a8186',
				transcode: 0
			})
				.then((res) => {
					this.loading = false;
					// console.log(res.data.code, '9999999');
					// if (res.status === 200 && res.data && res.data.code === 200 && res.data.data) {
					//   let { componentData, editorConfig, editorType } = JSON.parse(res.data.data['f708a9c6-2514-47ad-9056-3c3a1c37496f']);
					//   this.$store.commit('setConfigData', { componentData, editorConfig, editorType });
					//   this.inited = true;
					// }
					const { page_data } = res?.data?.data?.[0] || {};
					const { componentData, editorConfig, editorType } = isJSONStr(
						page_data
					)
						? JSON.parse(page_data)
						: page_data;
					this.$store.commit('setConfigData', {
						componentData,
						editorConfig,
						editorType
					});
					this.inited = true;
					// } catch (err) {
					// 	console.log('解析出错', err);
					//   this.$message.error('出错了，请联系管理员解决!');
					// }
					// }
				})
				.catch(() => {
					this.loading = false;
				});
		},
		/**
		 * @desc: 重新渲染
		 * @param {*}
		 * @return {*}
		 */
		reRender() {
			// console.log('重新渲染');
			this.inited = false;
			this.getViewData();
		}
	}
};
</script>

<style lang="less" scoped>
.index {
	width: 100%;
	// height: calc(100vh - 130px);
	overflow: hidden;
	overflow-y: auto;
	box-sizing: border-box;
	padding: 10px;
}
</style>
<style lang="less" scoped>
.nopadding {
	padding: 0px !important;
}
.home-main {
	height: 100%;
	width: 100%;
    box-sizing: border-box;
	overflow: hidden;
	padding: 14px;
	display: flex;
	.left-sider {
		width: 248px;
		height: 100%;
		background: #fff;
		border-right: 1px solid #eee;
		.left-sider-top {
			width: 100%;
			height: 50%;
			border-bottom: 1px solid #eee;
		}
		.left-sider-bottom {
			width: 100%;
			height: 50%;
		}
	}
	.main {
		flex-grow: 1;
		background: #fff;
	}
	.right-sider {
		width: 360px;
	}
	.ivu-drawer-content {
		background: #f5f5f5;
	}
	.cursor-pointer {
		cursor: pointer;
	}
}
</style>